<template>
  <v-container fluid class="mb-5">
  <v-card class="mt-5">
    <h1 class="ms-4">PRESUPUESTOS POR ENVIAR</h1>
    <v-divider></v-divider>
    <v-data-table 
    @click:row="pushRoute" fixed-header :items="presupuestos" :headers="presupuestosHeaders" class="fixed-checkbox" item-key="idPresupuesto"
      :single-select="true" :loading="loading" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [15, -1],
          }" checkbox-color="secondary" style="cursor:pointer;width: min-content !important">
      <template v-slot:body.prepend="{ headers }">
        <TableFilters :headers="headers" :items="presupuestos" v-model="inlineFilters"></TableFilters>
      </template>
        <template v-slot:item.n="{ item, index}">
          <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{ index+1 }}</td>
        </template>
        <template v-slot:item.tipo="{ item, index}">
          <td :style="'color: ' + (colorEvento.filter(cev => cev.title == item.tipo)[0]?.color || '')">
            {{ item.tipo }}
          </td>
        </template>
        <template v-slot:item.realiza="{ item, index}">
          <td :style="'color: #' + checkColor(item.datos.realiza)">
            {{ item.realiza }}
          </td>
        </template>
        <template v-slot:item.cola="{ item, index}">
          <td style="white-space: nowrap !important;font-weight: bold;padding-left: 30px !important;padding-right: 30px !important;text-align:center;font-size: x-large;" :class="item.cola > 5 ? 'error--text' : ''">
          {{ item.cola }}
          </td>
        </template>
        <template v-slot:item.estado="{ item, index}">
          <td style="white-space: nowrap !important;font-weight: bold;padding-left: 30px !important;padding-right: 30px !important;font-size: large;">
          {{ item.estado }}
          </td>
        </template>
        <template v-slot:item.conf="{ item, index}">
          <td style="white-space: nowrap !important;font-weight: bold;padding-left: 30px !important;padding-right: 30px !important;font-size: large;" :class="item.conf == 'NO' ? 'error--text' : ''">
          {{ item.conf }}
          </td>
        </template>
    </v-data-table>
  </v-card>
  <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
    <v-btn color="primary" fab @click="addPresupuesto" large>
      <v-icon>mdi-plus</v-icon>
      <!-- Añadir evento -->
    </v-btn>
  </div>
</v-container></template>
<script>
import { parseDate, perColumnFilter } from "@/utils";
export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: {
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorEvento:[
        {title:"BODA", color : '#b51442'}, 
        {title:"COMUNIÓN", color : '#f77102'}, 
        {title:"EVENTO", color : '#009e1d'}, 
        {title:"CORPORATIVO", color : '#2990ff'}, 
        {title:"PRUEBA BODA", color : '#f0be1a'}, 
      ],
      colores:[
        {title:"FF5733", emp : 1}, 
        {title:"FFBD33", emp : 2}, 
        {title:"84d404", emp : 3}, 
        {title:"7633FF", emp : 4}, 
        {title:"FFFFFF", emp : 7},
        {title:"e0d209", val : 10},
      ],
      inlineFilters: {
        idPresupuesto: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      presupuestos: [],
      loading: false,
      estados:[
        {title:"PTE SUPERVISIÓN",val:1}, 
        {title:"NO REALIZADO",val:2},
        {title:"ENVIADO",val:3}
      ],
      confirmado:[
        {title:"NO", val : 0}, 
        {title:"SI", val : 1},
      ],
      tipoEventos:[
        {title:"BODA", val : 1}, 
        {title:"COMUNIÓN", val : 2},
        {title:"CORPORATIVO", val : 4},
        {title:"EVENTO", val : 3},
        {title:"PRUEBA BODA", val : 5}
      ],
      espacios:[
        {title:"PISCIS", val : 1}, 
        {title:"HOTEL", val : 2}, 
        {title:"LAUS", val : 3}, 
        {title:"LALANNE", val : 4}, 
        {title:"CATERING ARAGÓN", val : 9},
        {title:"CATERING CATALUÑA", val : 10},
        {title:"LLOTJA", val : 12},
        {title:"VDV", val : 13},
        {title:"POR DEFINIR", val : 11},
        {title:"MAS DE PONENT", val : 16},
      ],
      tipoPptos:[
        {title : "NUEVO", val : 1}, 
        {title : "REVISIÓN", val : 2}
      ],
      introduce:[
        {title:"LAURA", val : 1}, 
        {title:"BERTA", val : 2}, 
        {title:"ISABEL", val : 3}, 
        {title:"MONICA", val : 4}, 
        {title:"BELEN", val : 10}, 
        {title:"JAVI", val : 5}, 
        {title:"JOSE", val : 6}, 
        {title:"OTROS", val : 7}],
      realiza:[
        {title:"LAURA", val : 1}, 
        {title:"BERTA", val : 2}, 
        {title:"ISABEL", val : 3}, 
        {title:"MONICA", val : 4}, 
        {title:"BELEN", val : 10}, 
        {title:"OTROS", val : 7}
      ],
      supervisaResp:[
        {title:"JAVI", val : 5}, 
        {title:"JOSE", val : 6}, 
        {title:"LAURA", val : 1},                
        {title:"PTE ASIGNAR", val : 10},                
      ],
    };
  },
  computed: {
    presupuestosHeaders() {
      return [
        { text: "Nº", value: "n",class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        // { text: "ENTRADA PPTO", value: "fecha", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "DÍAS EN COLA", value: "cola", class: "text-no-wrap sticky-header", align: "center", },
        { text: "ESTADO PPTO", value: "estado", dataType: "select", class: "text-no-wrap sticky-header", align: "center", },
        { text: "CONFIRMADO", value: "conf", dataType: "select", class: "text-no-wrap sticky-header", align: "center", },
        { text: "REALIZA", value: "realiza", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "FECHA EVENTO", value: "fechaEv", class: "text-no-wrap sticky-header" },
        { text: "TIPO EVENTO", value: "tipo", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "CLIENTE", value: "datos.cliente", class: "text-no-wrap sticky-header" },
        { text: "ESPACIO", value: "espacio", class: "text-no-wrap sticky-header", },
        { text: "UBICACIÓN", value: "datos.ubicacion", class: "text-no-wrap sticky-header", },
        { text: "TIPO PPTO", value: "tipoPpto", class: "text-no-wrap sticky-header", },
        { text: "INTRODUCE", value: "introduce", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "SUPERVISA", value: "supervisa", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "NOTAS", value: "datos.notas", class: "text-no-wrap sticky-header" },
      ]
        .filter(
          (h) => (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  mounted() {
    //   if (!(!!(this.$store.getters.getTokenPayload.permisos & this.$store.getters.getPermisos['RESUMEN_RENTABILIDAD']))) this.$router.push({ path: "/" });
    this.getPresupuestos();
  },
  methods: {
    checkColor(id){
      if (this.colores.filter(c => c.emp == id).length > 0) return this.colores.filter(c => c.emp == id)[0].title
      else return 'FFFFFF'
    },
    pushRoute(e){
      this.$router.push({ name: 'datosPresupuesto', params: { idPresupuesto: e.idPresupuesto } })
    },
    parseDate,
    async addPresupuesto() {
      this.$router.push({ name: "datosPresupuesto" })
    },
    async getPresupuestos() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/presupuestos/pte`,
      });
      this.presupuestos = data.map((m) => {
        return m;
      });
      this.presupuestos.forEach(p => {
        p.datos = JSON.parse(p.datos)
        var str = new Date(p.datos.fechaEv).toLocaleDateString('esp', {weekday : 'short'}).toUpperCase()
        str += ' ' + ('0' + new Date(p.datos.fechaEv).toLocaleDateString('esp', {day : 'numeric'}).toUpperCase()).slice(-2)
        str += '' + new Date(p.datos.fechaEv).toLocaleDateString('esp', {month : 'short'}).toUpperCase()
        str += ' ' + new Date(p.datos.fechaEv).toLocaleDateString('esp', {year : 'numeric'}).slice(-2)
        p.fechaEv = str
        p.estado = (p.datos.estado ? this.estados.filter(aux => aux.val == p.datos.estado)[0].title : null)
        p.conf = ((!isNaN(p.datos.confirmado) && p.datos.confirmado != null && p.datos.confirmado != undefined) ? this.confirmado.filter(aux => aux.val == p.datos.confirmado)[0].title : null)
        p.tipo = (p.datos.tipo ? this.tipoEventos.filter(aux => aux.val == p.datos.tipo)[0].title : null)
        p.espacio = (p.datos.espacio ? this.espacios.filter(aux => aux.val == p.datos.espacio)[0]?.title : null)
        p.tipoPpto = (p.datos.tipoPpto ? this.tipoPptos.filter(aux => aux.val == p.datos.tipoPpto)[0].title : null)
        p.introduce = (p.datos.introduce ? this.introduce.filter(aux => aux.val == p.datos.introduce)[0].title : null)
        p.realiza = (p.datos.realiza ? this.realiza.filter(aux => aux.val == p.datos.realiza)[0].title : null)
        p.supervisa = (p.datos.supervisa ? this.supervisaResp.filter(aux => aux.val == p.datos.supervisa)[0].title : null)
        p.cola = (Math.floor((Math.abs(new Date() - new Date(p.datos.fecha))) / (1000 * 60 * 60 * 24)))
      });
      this.presupuestos.sort((a,b) => {
        if (b.datos.fechaEv > a.datos.fechaEv) return -1;
        if (b.datos.fechaEv < a.datos.fechaEv) return 1;
        return 0;
      })
      this.loading = false;
    },
  },
};
</script>
<style>
.espaciador {
  padding: 5px !important
}
</style>